<template>
  <div class="selection_hall">
<!--    <NavBar title="选择大厅" class="nav_bottom"></NavBar>-->
    <main>
      <div class="item" v-for="v in testingArr" :key="v.id" @click="toTesting(v)">
        <p>{{ v.name }}</p>
        <div class="right"></div>
      </div>
    </main>
    <div class="submit" v-if="!see">
      <van-button plain type="info" @click="showHall=true">选择大厅</van-button>
      <van-button type="info" @click="sub">提交</van-button>
    </div>
    <van-popup v-model:show="showHall" round position="bottom">
      <el-empty v-if="!pickerFlag" description="没有可以选择的影厅"  image="https://img01.yzcdn.cn/vant/empty-image-default.png"></el-empty>
      <van-picker
          show-toolbar
          :columns="hallArr"
          :loading="loading"

          @cancel="showHall = false"
          @confirm="hallYes"
          v-else
      />
    </van-popup>
  </div>
</template>

<script>
import NavBar from "../components/NavBar";
import { Picker,Popup,Button,Toast } from 'vant';
import {GetOneCinemaList, GetTestingCinemaInfo, SetAllHall} from "../axios/api";
import {Loading,Empty} from "element-ui";
export default {
  name: "SelectionHall",
  created() {
    //获得当前影院的所有影厅
    let loadingInstance = Loading.service({ fullscreen: true , text: "加载中"});
    GetOneCinemaList({
      did:localStorage.getItem("did"),
      mid:this.$route.params.id,
      token:localStorage.getItem("token")
    }).then(respose=>{
      /*关闭加载动画*/
      this.loading=false
      if(respose.state==="1"){
        this.hallArr=Object.values(respose.data)
      }else if(respose.state==="0"){
        /*内容为空 显示空状态*/
        this.pickerFlag=false
      }
    }).catch(error=>{
      console.log(error);
    })

    /*获得已检查过的影厅*/
    GetTestingCinemaInfo({
      did:localStorage.getItem("did"),
      token:localStorage.getItem("token")
    }).then(respose=>{
      // console.log(respose)
      loadingInstance.close();
      if(respose.state == 1){
        this.testingArr=respose.data
      }else{
        Toast({message:respose?.msg,icon:"cross"})
      }
    }).catch(error=>{
      console.log(error);
    })
  },
  components:{
    NavBar,
    [Picker.name]:Picker,
    [Popup.name]:Popup,
    [Button.name]:Button,
    [Empty.name]:Empty
  },
  data(){
    return{
      showHall:false,
      hallArr:[],
      testingArr:[],
      loading: false,
      pickerFlag:true,
      see:this.$route.params.see
    }
  },
  methods:{
    hallYes(v){
      // console.log(v)
      this.$router.replace({name:'CinemaTesting',params:{mid:this.$route.params.id,hid:v.id,name:v.name}})
      /*//加入到要检测的数组里
      this.testingArr?this.testingArr.push(v):this.testingArr=[v];
      //关闭popup面板
      this.showHall=false*/
    },
    toTesting(v){
      // 查看已经填写的信息
      if(this.$route.params.see=='y'){
        this.$router.push({name:'CinemaTestingResult',params:{id:v.id},query:{see:'y'}})
      }else{
        this.$router.push({name:'CinemaTesting2',params:{mid:this.$route.params.id,hid:v.hid,hallid:v.id,name:v.name,flag:true}})
      }
    },
    sub(){
      /*提交所有影院的报告*/
      /*提交信息后 将缓存did删除*/
      SetAllHall({
        did:localStorage.getItem("did"),
        token:localStorage.getItem("token")
      }).then(respose=>{
        console.log(respose)
        if(respose.state==="1"){
          /*提交成功*/
          Toast.success("提交成功")
          localStorage.removeItem("did")
          this.$router.push("/testingUser")
        }else{
          Toast.fail("出现错误"+respose?.msg)
        }
      }).catch(error=>{
        console.log(error);
      })
    }
  }
}
</script>

<style scoped>
/*.nav_bottom{*/
/*  margin-bottom: 51px*/
/*}*/
.selection_hall{
  padding-top: 34.5px;
}
main {
  min-height: calc(100vh - 34.5px - 196px); /*减去33吧如果没有已经完成的大厅那么就是错误的，不减去的话没有大厅的时候可以下滑*/
  overflow: hidden; /*解决上方问题*/
}
.item {
  width: 690px;
  height: 150px;
  background: #FFFFFF;
  box-shadow: 0px 7px 31px 1px rgba(227, 227, 227, 0.51);
  border-radius: 20px;
  margin: 33px auto 0;
}
.item p {
  margin: 0;
  width: 590px;
  float: left;
  padding: 51px 0 51px 44px;
  height: 48px;
  font-size: 34px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #353535;
  line-height: 40px;
}
.item .right {
  float: left;
  width: 19px;
  height: 19px;
  border-top: 3px solid #000000;
  border-right: 3px solid #000000;
  transform: rotate(45deg);
  margin: 61px 28px 62px 0;
}
.selection_hall>>>.van-picker {
  width: 750px;
}
.selection_hall>>>.van-picker .van-picker__toolbar {
  width: 688px;
  height: 50px;
  margin: 0 36px 0 30px;
  padding: 30px 0 20px 0;
  border-bottom: 1px solid #F2F2F2;
}
.selection_hall>>>.van-picker .van-picker__toolbar .van-picker__cancel {
  font-size: 36px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5270FB;
  padding: 0;
}
.selection_hall>>>.van-picker .van-picker__toolbar .van-picker__confirm {
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
  color: #5270FB;
  padding: 0;
}
.selection_hall>>>.van-picker__columns{
  /*height: 456px!important;*/
}
.selection_hall>>>.van-picker .van-picker__columns .van-picker-column__wrapper .van-picker-column__item {
  font-size: 30px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #404040;
  /*line-height: 76px;
  height: 76px!important;*/
}
.selection_hall>>>.van-picker .van-picker__columns .van-picker-column__wrapper .van-picker-column__item--selected {
  width: 551px;
  height: 76px;
  background: #F2F2F2;
  border-radius: 10px;
  margin: 0 auto;
}
.submit {
  width: 750px;
  height: 166px;
  background: #FFFFFF;
  box-shadow: 0 4px 13px 5px rgba(112, 112, 112, 0.06);
  text-align: center;
  margin-top: 30px;
}
.submit button {
  width: 336px;
  height: 89px;
  border-radius: 18px;
  margin-top: 18px;
  font-size: 36px;
  font-family: PingFang SC-Heavy, PingFang SC;
  font-weight: 800;
}
.submit button:nth-last-of-type(1) {
  margin-left: 18px;
}
</style>
